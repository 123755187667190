
export default {
  name: 'ChildResource',
  filters: {
    initResourceDuration(value) {
      const result = parseInt(value)
      const h =
        Math.floor(result / 3600) < 10
          ? '0' + Math.floor(result / 3600)
          : Math.floor(result / 3600)
      const m =
        Math.floor((result / 60) % 60) < 10
          ? '0' + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60)
      const s =
        Math.floor(result % 60) < 10
          ? '0' + Math.floor(result % 60)
          : Math.floor(result % 60)

      let res = ''
      res += `${h}:`
      res += `${m || '00'}:`
      res += `${s}`
      return res
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    i1: {
      type: Number,
      default: 0
    },
    i2: {
      type: Number,
      default: 0
    },
    that: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    toggle(v, i, flag) {
      this.$emit('toggle', v, i, flag)
    }
  }
}
