// 引入高德地图
// const key = '0d4ec06a0d87bdbd65a21517aadb3cb8'
// const privateCode = 'fa72fef1c49c99f72030db5971408acc'
// const key = '0ce3ba3bce8cb0fdab6eb0cfa6688774'
// const privateCode = '4b993d2e8058818bea7164c26ef683f6'
export function gaodeMap(key, privateCode) {
  return new Promise(function(resolve, reject) {
    window.gaodeMap = function() {
      resolve()
    }
    window._AMapSecurityConfig = {
      securityJsCode: privateCode
    }
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `https://webapi.amap.com/maps?v=1.4.15&key=${key}`
    script.onerror = reject
    document.head.appendChild(script)
  })
}
