import Cookies from '~/utils/cookies.js'

export default function({ store, req, route, redirect }) {
  const { lang } = Cookies.getInServer(req)
  if (lang) {
    store.commit('SET_LANG', lang)
  }
  const routePath = route.path
  if (store.state.lang === 'en' && routePath.indexOf(`/${store.state.lang}/`) === -1) {
    console.log('lang redirect')
    return redirect({ path: `/${store.state.lang}${routePath}`, query: route.query })
  }
}
