export default {
  notData: '暂无数据',
  en: 'english',
  cn: '简体中文',
  common: '共',
  page: '页',
  data: '条数据',
  confirm: '确认',
  name: '姓名',
  and: '和',
  pleaseInputName: '请输入姓名',
  post: '职务',
  pleaseSelect: '请选择',
  companyName: '企业名称',
  pleaseInputCompanyName: '请输入企业名称',
  entScale: '企业规模',
  address: '企业地址',
  entIndustry: '所属行业',
  entCity: '所属城市',
  more: '更多',
  changes: '换一换',
  integral: '学分',
  creditHour: '积分',
  certificateCount: '证书',
  stick: '置顶',
  sheet: '张',
  point: '分',
  hitBooks: '去学习',
  learnTask: '学习任务',
  testTask: '考试任务',
  assessmentTask: '测试任务',
  coachTask: '辅导任务',
  notStarted: '未开始',
  learning: '学习中',
  haveLearned: '已学完',
  waitTest: '待考试',
  pass: '合格',
  fail: '不合格',
  waitMark: '待阅卷',
  absent: '缺考',
  designate: '指派',
  recommend: '推荐',
  hot: '热门',
  newest: '最新',
  uploadA: '上传一门',
  course: '课程',
  studyCourse: '学习课程',
  learnProject: '学习项目',
  peopleLearn: '人在学',
  myTask: '我的任务',
  trainRecords: '培训档案',
  myRecord: '我的记录',
  trainOperation: '培训操作',
  myNews: '我的消息',
  setLanguage: '设置语言',
  inviteMembersJoin: '邀请成员加入',
  search: '搜索',
  cancel: '取消',
  searchHistory: '搜索历史',
  emptyHistory: '清空历史',
  searchRecommended: '搜索推荐',
  loading: '加载中......',
  scrollBottom: '已经到底部，没有更多内容了',
  CheckOutMoreComments: '查看更多评论',
  checkMore: '查看更多',
  lineClass: '线上课',
  graphicClass: '图文课',
  liveClass: '直播课',
  learnState: '学习状态',
  all: '全部',
  notLearn: '未学习',
  sort: '排序',
  default: '默认',
  latest: '最新',
  hottest: '最热',
  comments: '评论',
  giveLike: '点赞',
  congratulation: '恭喜您',
  registerSuccess: '注册成功',
  jumpAfter: '之后自动跳转',
  toLogin: '前往登录 >',
  pleaseSelectCompany: '请选择要进入的公司',
  addCompany: '新增企业',
  chooseEnterpriseSuccess: '选择企业成功',
  headPortrait: '头像',
  phone: '手机',
  changePassword: '修改密码',
  Logout: '退出登录',
  unbounded: '未绑定',
  editSuccess: '修改成功',
  ranking: '排名',
  score: '得分',
  realTimeDataUpdate: '数据实时更新',
  takingPictures: '拍照',
  choosePhonePhotoAlbum: '从手机相册选择',
  complete: '完成',
  corporateAnnouncements: '企业公告',
  learnMessage: '学习消息',
  read: '已读',
  unread: '未读',
  pleaseInputPhone: '请输入手机号码',
  password: '密码',
  pleaseInputPassword: '请输入密码',
  code: '验证码',
  pleaseInputCode: '请输入验证码',
  getCode: '获取验证码',
  codeLogin: '验证码登录',
  passwordLogin: '密码登录',
  forgetPassword: '重置密码',
  register: '企业注册',
  useAccountLogin: '使用第三方账号快速登录',
  verifyMobileRegistered: '正在验证手机号是否已注册....',
  cellPhoneNumberRegistered: '手机号已注册',
  loginPassword: '登录密码',
  checkPassword: '重复密码',
  pleaseEnterLoginPassword: '请输入登录密码',
  pleaseEnterCheckPassword: '请输入重复密码',
  passwordLength: '{label}长度在 {minLength} 到 {maxLength} 个字符',
  passwordRuleCheck: '登录密码与重复密码不一致',
  pleaseInputLoginPassword: '请设置6-16位数字、字母符号组合',
  graded: '评分',
  beginLearn: '开始学习',
  continueLearn: '继续学习',
  courseware: '课件',
  lecturer: '讲师',
  courseIntroduction: '课程介绍',
  courseCatalog: '课程目录',
  studentsComment: '学员评论',
  watchRecord: '观看记录',
  watchCourseware: '资料列表',
  haveThumbUp: '已点赞',
  hasBeenCancelled: '已取消',
  details: '详情 >',
  introduce: '介绍',
  unfinished: '未完成',
  completed: '已完成',
  overdue: '逾期',
  exam: '考试',
  time: '次',
  totalScore: '总分',
  goal: '得分',
  notAvailable: '暂无',
  totalProgress: '总进度',
  periodValidity: '任务期限',
  qualifiedReward: '合格奖励',
  certificateQuality: '合格证书',
  learnProcess: '学习进度',
  testSchedule: '考试进度',
  solidExerciseSchedule: '实操进度',
  trainPurpose: '培训目的',
  completeJobEvaluation: '完成岗位测评',
  eligibilityCriteria: '合格标准',
  credits: '学分',
  have: '有',
  field: '场',
  pleaseSelectPost: '请选择职务',
  scale: '企业规模',
  pleaseSelectScale: '请选择企业规模',
  industry: '所属行业',
  pleaseSelectIndustry: '请选择所属行业',
  city: '所属城市',
  pleaseSelectCity: '请选择所属城市',
  synopsis: '简介',
  browse: '浏览',
  playCount: '播放次数',
  allComments: '全部评论',
  pleaseInputComment: '请输入评论',
  taskDirectory: '任务目录',
  pleaseApply: '请报名该项目',
  registerNow: '立即报名',
  learnContent: '学习内容',
  originalClass: '原创课',
  tip: '提示',
  deleteComment: '该评论一经删除，将不可恢复',
  newEmployeeTask: '新员工任务',
  takeTask: '选修任务',
  taskFile: '任务档案',
  learnFile: '学习档案',
  certificationFile: '认证档案',
  learnRecord: '学习记录',
  certificateRecord: '证书记录',
  courseRecord: '课程记录',
  learnProjectRecord: '学习项目记录',
  companyTitle: '请填写真实信息，让您的同事更便捷地找到您。',
  registerSuccessTip: '我已阅读并同意',
  userLog: '《服务协议与隐私政策》',
  loginSuccessUseLog: '我已阅读并同意',
  pleaseAgressLog: '请先阅读并同意《服务协议》与《隐私政策》',
  useService: '《服务协议》',
  usePrivacy: '《隐私政策》',
  paper: '试卷',
  pleaseInput: '请输入',
  reply: '回复',
  to: '至',
  testTime: '考试时间',
  testDuration: '考试时长',
  passMark: '合格分数',
  residueDegree: '考试次数',
  unlimited: '不限',
  nothing: '无',
  examTips: '温馨提示：考试一旦开始，倒计时结束后将自动交卷。',
  enterTest: '进入考试',
  singleTopicSelection: '单选题',
  multipleChoice: '多选题',
  trueOrFalseQuestions: '判断题',
  fillsUpTopic: '填空题',
  shortAnswerQuestions: '简答题',
  onTopic: '上一题',
  nextQuestion: '下一题',
  answerSheet: '答题卡',
  papers: '交卷',
  paperNoSaveTip: '您还有未答试题，确定交卷吗？',
  paperSaveTip: '确定交卷吗？',
  didNotAnswer: '未答',
  answered: '已答',
  toLearn: '返回学习任务 >',
  toCourse: '返回课程 >',
  finishExam: '恭喜你，完成考试',
  finishGrade: '您的成绩是',
  finishExamTip: '成绩只包含客观题的分数，主观题需讲师审核打分~以下统计只统计客观题~',
  finishSuccess: '正确(道)',
  finishError: '错误(道)',
  finishTotalTime: '总用时(分)',
  lookAtAnswer: '查看答案',
  answer: '答案',
  parsing: '解析',
  examinationSite: '考点',
  difficulty: '难度',
  simple: '简单',
  general: '一般',
  moreDifficult: '较难',
  difficult: '困难',
  pleaseInputEmpty: '请输入第',
  pleaseInputContent: '个空的答案',
  pleaseInputAnswer: '请输入答案',
  learnStartEndTime: '已超过可学习时间',
  nextStep: '下一步',
  answerError: '答错',
  answerSuccess: '答对',
  totalCredit: '累计学分',
  getCertificate: '获得证书',
  taskCompletionRate: '任务完成率',
  overdueRateTask: '任务逾期率',
  timedTask: '定时任务',
  totalCredits: '累计总学分',
  toadyGet: '今日获得',
  pleaseInputSearch: '请输入搜索内容',
  back: '返回',
  paperNeedTo: '该试卷需要在',
  paperFinish: '内完成',
  passTitle: '合格',
  passComplete: '完成',
  goToCommunity: '去社区交流',
  createName: '创建人',
  examWillBegin: '开始考试',
  continueTest: '继续考试',
  progress: '进度',
  operation: '操作',
  examinationCompleted: '已完成考试',
  correct: '正确',
  error: '错误',
  PersonalIntroduction: '个人介绍',
  noIntroduction: '暂无介绍',
  pleaseEnterContent: '请输入内容',
  returnHome: '返回首页',
  noLearn: '请先报名',
  technicalPost: '职称',
  bindPhone: '绑定手机',
  reExamination: '重新考试',
  registrationEnterpriseInformation: '企业信息注册',
  returnLogin: '返回登录',
  pleaseInputChangePassword: '请再次输入密码',
  passwordNotCheck: '两次输入密码不一致',
  codeError: '验证码错误',
  accountRegister: '该账号已存在',
  in: '点击进入',
  pleaseInputMsg: '请填写以下信息',
  directory: '目录',
  courseExam: '课后测试',
  pleaseLearn: '该内容需要再学习',
  cardPlay: '点击打卡',
  computedPlayCard: '完成打卡才能继续学习哦~',
  learnSuccess: '算完成',
  noUpdate: '未更新',
  free: '免费',
  no: '第',
  chapter: '章',
  chapterNumber: '章节',
  dataLoadError: '数据加载失败，请稍后重试',
  videoNoUpdate: '该视频未更新',
  bugWatch: '购买后才可以观看',
  CreditRecord: '学分记录',
  CertificateRecords: '证书记录',
  CourseRecord: '课程记录',
  LearningProjectRecord: '学习项目记录',
  startDate: '开始日期',
  endDate: '结束日期',
  export: '导出',
  examRemark: '考前说明',
  CreditsContent: '学分内容',
  creditBehavior: '学分行为',
  creditOrigin: '学分来源',
  timer: '时间',
  batchDelete: '批量删除',
  batchRead: '标记已读',
  lookDetail: '查看详情',
  pleaseSelectNotice: '请选择公告!',
  sureDeleteNotice: '确定批量删除公告?',
  deleteError: '删除失败!',
  actionError: '操作失败!',
  sureReadNotice: '确定批量阅读公告?',
  courseName: '课程名称',
  updateTime: '更新时间',
  status: '状态',
  getCredits: '获得学分',
  taskName: '任务名称',
  startTime: '开始时间',
  taskDeadline: '任务期限',
  taskType: '任务类型',
  progressAchievement: '进度/成绩',
  beforeDay: '天之前',
  beforeHour: '小时之前',
  beforeMinutes: '分钟之前',
  sureDeleteComment: '确定删除这条评论?',
  deleteSuccessComment: '删除评论成功!',
  beforeLoginComment: '登录后才可以评论',
  commentSuccess: '评论成功!',
  commentError: '评论失败!',
  courseType: '课程类型',
  coursewareNumber: '课件数',
  loginLearn: '登录学习',
  courseRemove: '课程已下架',
  loginWatch: '登录观看',
  addCar: '加入购物车',
  ImmediateSettlement: '立即结算',
  SharingMakes: '分享赚',
  inviteFriendsLearn: '邀请好友学习',
  inviteEveryOne: '每邀请一位你将得',
  inviteLink: '链接邀请',
  copyLink: '复制链接',
  AuthorizationPurchase: '授权购买',
  pleaseInputPurchaseNumber: '输入购买授权数量',
  loginRate: '登录后才可以评分',
  rateSuccess: '评分成功',
  loginShare: '登录后才可以分享赚钱',
  copySuccess: '复制成功',
  loginBuy: '登录后才可以加入授权购买',
  loginAddCar: '登录后才可以加入购物车',
  loginLike: '登录后才可以点赞',
  likeSuccess: '点赞成功!',
  cancelLike: '已取消点赞!',
  courseRate: '课程评分范围为1~5分!',
  learnStudyNum: '学习人数',
  collectionNum: '收藏人数',
  collection: '收藏',
  loginCollection: '登录后才可以收藏',
  cancelCollection: '成功取消收藏！',
  collectionSuccess: '收藏成功！',
  applyRegistration: '申请报名',
  applySuccess: '已申请报名，须等待审核！',
  applyRegistrationError: '报名审核不通过!',
  applyRegistrationSuccess: '报名已通过审核！',
  registrationApproval: '报名审批中',
  registrationFailed: '审批不通过',
  pleaseLogin: '请先登录!',
  submitSuccess: '提交成功',
  pleaseDeleteComment: '此操作将永久删除该评论, 是否继续',
  BreakDown: '详解',
  pay: '付费',
  nexPage: '下一页',
  returnFirst: '返回第一页',
  sureExport: '确认导出数据?',
  fileName: '文件',
  clearLog: '清空记录',
  oldPassword: '旧密码',
  pleaseInputOldPassword: '请输入旧密码',
  newPassword: '新密码',
  pleaseInputNewPassword: '请输入新密码',
  repeatNewPassword: '重复新密码',
  pleaseInputRepeatNewPassword: '请输入确认新密码',
  pleaseInputSuccessPassword: '请输入正确的密码',
  pleaseInputSuccessNewPassword: '请输入正确的新密码',
  pleaseInputSuccessRepeatPassword: '请输入正确的确认密码',
  pleaseInputSuccessLengthPassword: '密码长度在 8 到 30 个字符',
  passwordNoSure: '确认密码与新密码不一致',
  pleasePwdSizeRule: '请输入8~30位, 必须包含大小写字母、特殊字符与数字',
  passwordRule: '密码必须包含大小写字母、字符及数字',
  sureClearLog: '确认清空当前记录?',
  ongoing: '进行中',
  permanently: '永久有效',
  month: '个月',
  pending: '待审批',
  certificationName: '认证名称',
  completedStudy: '已完成学习',
  pleaseInputCertificate: '请输入证书名称',
  automaticIssued: '自动颁发',
  manualIssued: '手动颁发',
  sortIndex: '序号',
  certificateName: '证书名称',
  userName: '真实名称',
  certificateNumber: '证书编号',
  certificateOrigin: '证书来源',
  acquisitionTime: '获取时间',
  issueWay: '颁发方式',
  statusCertificate: '证书状态',
  outDate: '已过期',
  hold: '持有中',
  loginSuccess: '登录成功',
  noCompany: '没有登录企业',
  refresh: '刷新',
  notYetStart: '尚未开播',
  examRecordTitle: '考试记录',
  canResit: '可补考',
  AnswerTime: '答卷时间',
  paperTotal: '试卷总分',
  answerHour: '作答时长',
  examResult: '考试结果',
  readerUser: '阅卷人',
  detail: '详情',
  submitTime: '提交时间',
  answerTime: '答题时长',
  evaluation: '评定',
  haveExam: '已考试',
  total: '共',
  pages: '页，',
  count: '条数据',
  notReadPaper: '暂时无法查看解析',
  screenCuttingNotAllowed: '本次考试不允许切屏，切屏后自动交卷',
  screenCutTipsOne: '警告，切屏超过',
  screenCutTipsTwo: '次后，将自动交卷',
  screenCuttingTimesExceedsLimit: '切屏次数超出限制，自动交卷',
  examTimeOver: '考试时间已经结束',
  remark: '评语',
  applyTip: '无法学习，请等待报名审核',
  applyTip1: '无法学习，请先报名',
  rate: {
    one: '营养不足',
    two: '有点营养',
    three: '养分很多',
    four: '养分充足',
    five: '干货满满',
  },
  login: {
    DingTalk: '钉钉',
    pleaseLoginDingTalk: '请用“钉钉”扫码登陆',
    EnterpriseWeChat: '企业微信',
    pleaseLoginEnterpriseWeChat: '请用“企业微信”扫码登陆',
    toLogin: '账号登录',
    toNo: '工号登录',
    pleaseInputPhone: '请输入手机号码',
    pleaseInputNo: '请输入工号',
    pleaseInputCode: '请输入验证码',
    sendCode: '获取验证码',
    pleaseInputPassword: '请输入密码',
    login: '登录',
    passwordLogin: '密码登录',
    codeLogin: '验证码登录',
    forgetPassword: '重置密码',
    vaildatePhone: '验证手机号',
    setNewPsd: '设置新密码',
    setSuccess: '修改成功',
    register: '注册',
    pleaseInputSuccessPhone: '请输入正确手机号码',
    pleaseSetPassword: '请设置密码',
    pleaseInputInfo: '请完善以下信息',
    pleaseInputCheckPassword: '再输入密码',
  },
  home: {
    admin: '管理后台',
    sign: '每日签到',
    task: '我的任务',
    change: '切换企业',
    contribute: '我的贡献',
    certificates: '我的证书',
    approval: '实操作业审批',
    credit: '学分(分)',
    certificateCount: '证书(张)',
    integrals: '积分(分)',
    changeLang: '切换语言:',
    logout: '退出登录',
    more: '更多',
    credits: '学分',
    integral: '积分',
    certificate: '证书',
    toLearn: '去学习',
    point: '分',
    part: '张',
    recommended: '推荐',
    hot: '热门',
    latest: '最新',
    notLearn: '未学习',
    inStudy: '学习中',
    learnComplete: '学习完成',
    learnProgram: '学习项目',
    lineClass: '线上课',
    graphicClass: '图文课',
    assigned: '指派',
    study: '人在学',
    waitExam: '待考试',
    passExam: '合格',
    notPassExam: '不合格',
    readExam: '待阅卷',
    notExam: '缺考',
    uploadType: '上传一门',
    course: '课程',
    learningProjects: '学习项目',
    changeCourse: '换一换',
    learningTask: '学习任务',
    examTask: '考试任务',
    assessmentTask: '测评任务',
    coachTask: '辅导任务',
    top: '置顶',
    rank: '排名',
    name: '姓名',
    logoSize: '大小不能超过5M',
  },
  upload: {
    clear: '取消上传',
    clearTip: '确认取消上传吗?',
    uploadtTip: '正在上传',
    uploadtTip2: '上传格式只能是图片、wrod、pdf格式!',
    resetUpload: '重新上传',
    download: '下载',
    seek: '查看',
    close: '关闭',
    attachment: '上传附件',
  },
  VX_account: {
    newestStudyTime: '最新学习时间',
    projectName: '项目名称',
    trainingRequirement: '培训需求',
    trainingType: '培训类型',
    trainingForm: '培训形式',
    trainingPurpose: '培训目的',
    planTheNumberofPeople: '计划人数',
    estimatedCost: '预计费用',
    estimatedClassHours: '预计课时',
    trainingOrgLec: '培训机构/讲师',
    trainingObject: '培训对象',
    trainingYear: '培训年份',
    trainingQuarter: '培训季度',
    trainingMonth: '培训月份',
    trainingAddress: '培训地点',
    executiveDepartment: '执行部门',
    superintendent: '负责人',
    executor: '执行人',
    selectMember: '选择人员',
    determine: '确定',
    createTrainingRequirement: '新建培训需求',
    trainingRequirementInputPlaceholder: '请输入培训需求',
    trainingYearSelectPlaceholder: '请选择培训年份',
    trainingQuarterSelectPlaceholder: '请选择培训季度',
    trainingMonthSelectPlaceholder: '请选择培训月份',
    superintendentSelectPlaceholder: '请选择负责人',
    planName: '计划名称',
    source: '来源',
    creatorName: '创建人',
    gmtCreate: '创建时间',
    relatedTask: '关联任务',
    trainingTimePeriod: '培训时间',
    completionRate: '完成率',
    passRate: '合格率',
    moreOperations: '更多操作',
    execute: '执行',
    executionDetails: '执行明细',
    edit: '编辑',
    editMonthlyPlans: '编辑月度计划',
    expireTime: '到期时间',
    certificateView: '证书查看',
    downloadPicture: '下载图片',
    certificateNo: '证书编号',
    issueTime: '颁发时间',
    certificateTime: '生效时间',
    certificateeffectiveStartTime: '到期时间',
    obtainType: '获得方式',
    autoIssue: '自动颁发',
    manuIssue: '手动颁发',
    issueSource: '颁发来源',
    issueCause: '颁发原因',
    quashCause: '撤销原因',
    todayCount: '今日获得学分',
    totalCount: '累计总学分',
    type: '类型',
    changeTime: '变更时间',
    backToLearn: '返回学习',
    backToExamTask: '返回考试任务',
    inEffect: '生效中',
    rescinded: '已撤销',
    indefiniteTime: '无限期',
    productName: '商品名称',
    exchangeCount: '兑换数量',
    consumptionIntegral: '消耗积分',
    exchangeTime: '兑换时间',
    issueProtectTime: '发放时间',
    todayIntegralTotal: '今日累计积分',
    integralTotal: '累计积分',
    integralRules: '积分规则',
    integralUsable: '可用积分',
    toExchangeProduct: '兑换好礼',
    courseInteraction: '课程互动',
    contribution: '贡献',
    integralBehavior: '积分行为',
    operationName: '操作名称',
    singleScore: '单次奖惩分值',
    upperIntegralDay: '每日积分上限',
    noUpperIntergral: '无限',
    validityPeriod: '有效周期',
    integralRulesTips: '温馨提示',
    integralRulesTipOne: '1、累计积分=累计获得的积分-因抵值内容等被扣除的积分',
    integralRulesTipTwo: '2、学员可使用积分在相应页面兑换商品，兑换要求、使用规则等详情具体以页面展示为准。',
    integralRulesTipThree: '3、由于受限于库存数量，积分商品兑完即止，先兑先得。',
    notFinish: '未结束',
    finished: '已结束',
    settled: '已结算',
    taskNamePlaceholder: '根据任务名称搜索',
    offlineClassName: '线下课名称',
    classStartTime: '开课时间',
    courseAddress: '上课地址',
    trainingAttachment: '培训附件',
    courseEvaluate: '评价课程',
    evaluateCourse: '给课程评价',
    questinonaireModelCancel: '取 消',
    questinonaireModelDetermine: '确 定',
    lecturerEvaluate: '评价讲师',
    evaluateLecturer: '给讲师评价',
    totalEvaluateCount: '应评价人数',
    waitEvaluateCount: '待评价人数',
    completeEvaluateCount: '已评价人数',
    evaluateTrainees: '给学员评分',
    evaluateTrainee: '给学员评价',
    questionaireEvaluate: '问卷评价',
    remove: '移除',
    announcement: '公告',
    announcementTitle: '公告标题',
    userMyEvaluate: '我的评价',
    userEvaluateMe: '评价我的',
    traineesEvaluate: '学员对我的评价',
    lecturerEvaluateMe: '讲师对我的评价',
    commitPracticeTask: '提交实操作业',
    taskRequirement: '作业要求',
    attachment: '附件',
    step: '第{index}步',
    pracDialogPlaceholder: '请描述操作步骤（选填）',
    pracDialogTips: '支持图片、文档、压缩包，最多支持5个附件',
    addStep: '添加步骤',
    saveDraft: '存草稿',
    commit: '提交',
    submitWarning: '请填写内容',
    startSurvey: '开始调研',
    statisticsResult: '统计结果',
    selfEvaluate: '自评',
    othersEvaluate: '他评',
    awardCredit: '奖励学分',
    repeatedExam: '重考',
    examStatics: '考试数据',
    waitEvaluation: '待评定',
    allMessage: '全部消息',
    unreadMessage: '未读消息',
    readMessage: '已读消息',
    userMsgPlaceholder: '请选择消息类型',
    messageName: '消息名称',
    messageType: '消息类型',
    messageDetails: '消息详情',
    close: '关闭',
    batchReadTip: '确定将勾选的{length}条消息标为已读？',
    batchDeleteTip: '确定删除已勾选的{length}条消息？',
    deleteTip: '删除提示',
    attendanceManagement: '考勤管理',
    attendanceName: '考勤名称',
    relatedOfflineClass: '关联线下课',
    sourceName: '来源名称',
    signIn: '签到',
    signOut: '签退',
    attendanceQRCode: '考勤二维码',
    scoreRecord: '积分记录',
    exchangeRecord: '兑换记录',
    effectiveStatusPlaceholder: '请选择证书状态',
    startDatePlaceholder: '开始日期',
    endDatePlaceholder: '结束日期',
    issueStatusPlaceholder: '请选择发放状态',
    notIssue: '待发放',
    issued: '已发放',
    surveyTask: '调研任务',
    evaluationTask: '评估任务',
    trainingPlan: '培训计划',
    evaluationTaskOfNamePlaceholder: '根据评估任务名称搜索',
    yearPlaceholder: '请选择年份',
    quarterPlaceholder: '请选择季度',
    monthPlaceholder: '请选择月份',
    planOfNamePlaceholder: '根据计划名称搜索',
    uploadTips: '点击上传',
    executorSelect: '选择执行人',
    endExamTip: '是否结束考试?',
    handInExamPaper: '是否交卷？',
    autoHandInExamPaper: '本次考试不允许切屏，正在自动交卷',
    trainRecords: '培训记录',
    basicInfo: '基本信息',
    taskTitlePlaceholder: '请输入任务名称',
    classification: '所属分类',
    validityTime: '有效期',
    startTimePlaceholder: '开始时间',
    endTimePlaceholder: '结束时间',
    departmentName: '组织部门',
    costBudget: '费用预算',
    yuan: '元',
    stageTask: '阶段任务',
    studyMode: '学习模式',
    notSupportOfflineTips: '解锁模式暂不支持线下课',
    freeMode: '自由模式',
    unlockMode: '解锁模式',
    stageContentSetting: '阶段内容设置',
    stage: '第{index}阶段',
    stageName: '阶段名称',
    pleaseInputStageName: '请输入阶段名称',
    stageExplanation: '阶段说明',
    unlockTimePlaceholder: '解锁时间',
    stageContent: '阶段内容',
    name: '名称',
    contentDuration: '内容时长',
    nothaveDuration: '暂无时长',
    pleaseSelectUnlockTime: '请选择解锁时间',
    changeResource: '更换资源',
    delete: '删除',
    drag: '拖拽',
    withinStageThroughLevel: '阶段内闯关',
    betweenStageThroughLevel: '阶段间闯关',
    unlockTaskTipOne: '开启后，当前阶段内内容员工必须按顺序解锁学习',
    unlockTaskTipTwo: '开启后，各阶段员工必须按顺序解锁学习（例如：员工学完阶段一后才能学习阶段二内容）',
    progressSync: '进度同步',
    progressSyncTip: '添加的课程/素材同步员工在其他途径的学习进度。<br/> 同步机制：播放时才同步进度',
    synchronize: '同步',
    notSynchronize: '不同步',
    estimatedTaskHours: '估算学时',
    estimatedTaskHoursStandards: '估算学时标准：<br/> 视频：按照视频时长统计<br/> 音频：按照音频时长统计<br/> 其他内容不计入估算<br/> 学习项目统计包含课件、课程中的音视<br/> 频时长以及线下培训中的开课时间时长',
    assignRange: '指派范围',
    assignRangeSetting: '设置指派范围',
    select: '选择',
    selectedObject: '已选对象',
    selectedPerson: '已选人员',
    qualifiedSetting: '合格设置',
    qualifiedStandardTip: '学员学习整个项目是否合格的依据',
    taskProgressReach: '学习进度达到',
    examPass: '考试通过',
    practicePass: '实操作业通过',
    qualifiedRewardTip: '学员是否可以获得项目合格奖励的依据',
    getCertificate: '合格后获得证书',
    otherSetting: '其他设置',
    learningTimeSetting: '设置文档学习时长',
    learningTimeTip: '员工学习课件必须达到的时长',
    documentLearningTime: '文档学习时长',
    secondsPerPage: '秒/页',
    learningTimeSettingTips: '1.如未设置，员工学习文档进度用浏览页数来计算，不计入学习时长。<br/> 2.设置后，员工学习进度用学习时长来计算，学习时长最大计为每页学习时长*文档页数。',
    faceRecognition: '人脸识别',
    faceRecognitionTip: '开启后，员工学习阶段的各内容时需人脸验证通过后方可进入学习',
    save: '保存',
    publish: '发布',
    pleaseSelectAssignRange: '请选择指派范围',
    pleaseSelectEligibilityCriteria: '请选择合格标准',
    pleaseSelectClassification: '请选择所属分类',
    pleaseInputValidityPeriod: '请输入有效期',
    pleaseInputTaskName: '请输入任务名称',
    pleaseSelectStageContent: '请选择阶段内容',
    selectAssignRange: '选择指派范围',
    firstStage: '第一阶段',
    delTip: '确定删除【{name}】？删除后对应的配置内容也会一起删除，请谨慎操作',
    changeFile: '更换文件',
    selectFile: '选择文件',
    changeOnlineCourse: '更换线上课',
    changeGraphicCourse: '更换图文课',
    changeLiveCourse: '更换直播课',
    selectOnlineCourse: '选择线上课',
    selectGraphicCourse: '选择图文课',
    selectLiveCourse: '选择直播课',
    addExamination: '添加考试',
    editExamination: '编辑考试',
    addHomework: '添加实操作业',
    editHomework: '编辑实操作业',
    addOfflieCourse: '添加线下课',
    editOfflieCourse: '编辑线下课',
    addAttendance: '添加考勤',
    editAttendance: '编辑考勤',
    addInvestigate: '添加调研',
    editInvestigate: '编辑调研',
    deletePhraseTip: '删除后，所有已派发任务会同步删除，进度重新计算。该操作不可逆，确定要删除？',
    selectMembers: '选择指派范围',
    selectCertificate: '证书选择',
    stageTaskTip: '阶段内容不能为空',
    stageContentTip: '中的阶段内容不能为空',
    assignCount: '指派人数',
    participationCount: '参加人数',
    completeCount: '完成人数',
    completeRate: '完成率(%)',
    passRatePercent: '合格率(%)',
    unbind: '解除绑定',
    publishSuccess: '发布成功',
    unbindTip: '解除绑定后，任务和计划不再产生关联关系。确定要解除绑定？',
    deleteDetailTip: '确定删除？',
    modifierName: '提交人',
    planDetails: '计划明细',
    expectTrainingMonth: '预计培训月份',
    assignor: '指派人',
    beyondPeriod: '任务已超出有效期，不可进行学习',
    notLive: '暂未开播',
    liveHasEnd: '直播已结束，视频生成中~',
    videoGenerating: '视频生成中~',
    totalPoint: '总',
    essayPoint: '作文得分',
    month: '月',
    integralTypePlaceholder: '请选择积分类型',
    sheet: '{count}张',
    reLearn: '重新学习',
    announcementTotal: '全部公告',
    announcementUnread: '未读公告',
    announcementRead: '已读公告',
    examExpired: '考试已超过有效期！',
    inputAnswerPlaceholder: '请输入第{index}个空的答案',
    finishInTime: '该试卷需要在{count}内完成',
    treeName: '存储位置',
    fileNameSearchPlaceholder: '根据文件名称搜索',
    currentPageData: '全部当页',
    selectCountAtMost: '最多选择{count}张！',
    selectContentPlaceholder: '请选择数据',
    resourceClassification: '资源分类',
    courseNameSearchPlaceholder: '根据课程名称搜索',
    originalOrNotPlaceholder: '是否原创',
    original: '原创',
    unoriginal: '非原创',
    selectCourse: '选择课程',
    pleaseSelectCourse: '请选择课程',
    quotePaper: '引用试卷',
    examName: '考试名称',
    passLine: '及格线',
    passScore: '及格分',
    examTotalScore: '试题总分',
    examDurationTip: '时长不超过300分钟',
    examDescription: '考试说明',
    examSetting: '考试设置',
    facialRecognitionTip: '开启后，员工移动端进入学习时需要通过人脸识别后方能进入学习',
    unlimited: '不限次数',
    suspendAfterPassing: '及格后中止',
    customTimes: '自定义次数',
    gradeSetting: '成绩设置',
    recordLatestContent: '记录最新数据',
    recordHighestContent: '记录最高成绩',
    switchScreenCount: '切屏次数',
    switchScreenTip: '设置防切屏后，PC端填空题和简答题学员作答时无法上传图片',
    notAllowed: '不允许',
    custom: '自定义',
    answerOrder: '作答顺序',
    answerOrderTipOne: '试题乱序：随机打乱各员工的题目顺序',
    answerOrderTipTwo: '选项乱序：随机打乱各员工题目内的试题选项顺序',
    questionOutOfOrder: '试题乱序',
    optionOutOfOrder: '选项乱序',
    viewSetting: '学员查看设置',
    display: '显示',
    hide: '隐藏',
    viewAnswerDetails: '查看答题详情',
    afterMarking: '阅卷后',
    afterPassing: '及格后',
    examEnd: '考试结束',
    invisible: '不可见',
    viewContent: '查看内容',
    viewContentTypeOne: '对错和答案解析',
    viewContentTypetTwo: '只看对错',
    knowledgePoint: '知识点',
    viewRank: '查看排行',
    afterHandingInPaper: '交卷后',
    markingSetting: '阅卷设置',
    markingType: '阅卷方式',
    markingTypeOne: '系统阅卷',
    markingTypeTwo: '人工阅卷',
    saveAndAdd: '保存并继续添加',
    selectPaperPlaceholder: '请选择试卷',
    selectPaper: '选择试卷',
    selectMarkingUserPlaceholder: '选择阅卷人',
    addSuccess: '添加成功',
    homeworkName: '作业名称',
    homeworkNamePlaceholder: '请简要描述',
    taskRequirementPlaceholder: '请输入实操规范要求',
    attachmentTip: '上传的附件将会对学员展示作为作业参考',
    fileName: '文件名',
    homeworkType: '作业类型',
    homeworkTypeOne: '常规作业',
    homeworkTypeTwo: '新人作业',
    homeworkTypeSelect: '类型不对学员展示，仅在“实操报表”中筛选使用',
    selectAuditUser: '选择审批人',
    courseDuration: '课程时长',
    confirmCourse: '讲师确认课程安排',
    notComputeCourseCost: '不计课时费',
    contentDescriptionPlaceholder: '可写下您的详细课程介绍或培训目的',
    moreSetting: '更多设置',
    lecturerEvaluateTrainees: '讲师评价学员',
    start: '开启',
    directEvaluation: '直接评分',
    questionnaire: '问卷调查',
    selectQuestionnaire: '选择问卷',
    fullMarks: '满分',
    traineesEvaluateLecturer: '学员评价讲师',
    evaluationUseStar: '星评',
    traineesEvaluateCourse: '学员评价课程',
    offlineExamination: '线下考核',
    offlineExaminationTipOne: '开启后允许录入学员成绩',
    offlineExaminationTipTwo: '讲师、签到负责人和管理员可以录入学员成绩',
    messageRemind: '消息提醒',
    beforeTrainingStart: '培训开始前',
    remindLecturer: '提醒讲师',
    remindStudent: '提醒学员',
    remindTip: '分钟发送提醒',
    other: '其他',
    allowUpload: '允许上传活动照片等附件',
    pleaseSelectLecturer: '请选择讲师',
    selectLecturer: '选择讲师',
    relatedOfflineClassTip: '关联线下课后,该考勤仅对被关联的线下课有效',
    offlineSelectPlaceholder: '请选择线下课',
    attendanceTimeLimit: '考勤时间限制',
    detailedAddress: '详细地址',
    attendanceEffectiveRange: '考勤有效范围',
    meter: '米',
    addAttendanceAddress: '添加考勤地址',
    dynamicQRCode: '动态二维码',
    dynamicQRCodeTip: '开启后二维码刷新频率：10秒刷新',
    remindAttendanceManager: '提醒考勤负责人',
    beforeAttendance: '考勤开始前',
    reminderMinute: '分钟提醒',
    attendanceTimeLimitTip: '请选择考勤时间限制',
    signInTimeTip: '请选择签到时间',
    signOutTimeTip: '请选择签退时间',
    selectUser: '选择负责人',
    pleaseSelectQuestionnaire: '请选择问卷',
    investigationName: '调研名称',
    searchContent: '搜索内容',
    user: '人员',
    noAuthTipOne: '您没有该部门管辖权限，请选择该',
    noAuthTipTwo: '部门下有管辖权限的子部门人员',
    empNo: '所属工号',
    noContent: '没有搜到内容',
    selectedUser: '已选员工',
    pleaseSelectUser: '请选择添加对象/员工',
    paperNameSearchPlaceholder: '根据试卷名称搜索',
    create: '创建',
    addressInputPlaceholder: '输入地址搜索',
    pleaseSelectAddress: '请选择地址',
    classificationCatalogue: '分类目录',
    questionnaireName: '问卷名称',
    certificateSearchPlaceholder: '根据证书名称搜索',
    department: '部门',
    position: '岗位',
    selectedUsers: '已选对象/员工',
    lecturerOrTagSearchPlaceholder: '根据讲师或标签搜索',
    lectured: '已授课',
    yes: '是',
    no: '否',
    goToDetailTip: '请移步到移动端学习',
    known: '知道了',
    submitTime: '交卷时间',
    passScores: '及格分数',
    importantCourse: '重点课',
    attendanceNamePlaceholder: '根据考勤名称搜索',
    startOnLive: '开播时间',
    signInQrCode: '签到二维码',
    signOutQrCode: '签退二维码',
    refreshQrCode: '刷新二维码',
    relatedCourse: '关联课程',
    attendanceAddress: '考勤地址',
    attendanceType: '考勤方式：扫码（考勤地址{range}米内）',
    svgList: {
      materialSvg: '素材',
      onlineSvg: '线上课',
      graphicSvg: '图文课',
      liveSvg: '直播课',
      examSvg: '考试',
      homeworkSvg: '实操作业',
      offlineSvg: '线下课',
      checkinSvg: '考勤',
      investigateSvg: '调研',
    },
    trainingTypeEnum: {
      online: '线上',
      offline: '线下',
      mixed: '混合',
      other: '其他培训',
    },
    trainingMonthEnum: {
      January: '1月',
      February: '2月',
      March: '3月',
      April: '4月',
      May: '5月',
      June: '6月',
      July: '7月',
      August: '8月',
      September: '9月',
      October: '10月',
      November: '11月',
      December: '12月',
    },
    postStatusEnum: {
      unpublished: '未发布',
      published: '已发布',
      reject: '驳回',
      inApproval: '审批中',
    },
    ruleTypeEnum: {
      courseInteraction: '课程互动',
      contribution: '贡献',
      attendance: '出席',
    },
    operateSceneEnum: {
      operateSceneOne: '完成一次课程评分(线上课、学习项目)',
      operateSceneTwo: '完成一次课程点赞(线上课、学习项目)',
      operateSceneThree: '完成一次课程评论/回复(线上课、学习项目)',
      operateSceneFour: '完成一次线上授课(学习项目)',
      operateSceneFive: '完成一次线上授课(线上课)',
      operateSceneSix: '完成一次线下授课(线下课)',
      operateSceneSeven: '完成一次线上授课(直播课)',
      operateSceneEight: '迟到/早退≤30分钟',
      operateSceneNine: '迟到/早退>30分钟，旷课',
      operateSceneTen: '考勤缺卡(线下课)',
    },
    validityPeriodTypeEnum: {
      disposable: '一次性',
      everyDay: '每日',
    },
    studyStatusEnums: {
      notLearned: '未学习',
      learning: '进行中',
      ended: '结束',
    },
    trainingQuarterEnum: {
      firstQuarter: '第一季度',
      secondQuarter: '第二季度',
      thirdQuarter: '第三季度',
      fourthQuarter: '第四季度',
    },
    courseTypeEnum: {
      video: '线上课',
      imageText: '图文课',
      live: '直播课',
      offline: '线下课',
    },
    changeResourceTypeEnums: {
      vodOnline: '线上课',
      imageText: '图文课',
      studyProject: '学习项目',
      studyTask: '学习任务',
      examTask: '考试任务',
      postMap: '岗位地图',
      evaluationTask: '测评任务',
      postMapPath: '岗位地图路径',
    },
    changeCategoryEnum: {
      courseInteractive: '课程互动',
      contribution: '贡献',
      attendance: '出席',
      lowValueInteraction: '低值互动',
      lowValueContent: '低值内容',
      integralExchange: '积分兑换',
      integralClearing: '积分清零',
    },
    taskTypeEnums: {
      studyProject: '学习项目',
      studyTask: '学习任务',
      examTask: '考试任务',
      timeExam: '定时考试',
      external: '外部',
      manualIssue: '手动颁发',
      postCertification: '岗位认证',
      evaluationTask: '测评任务',
    },
    NoticeTypeEnum: {
      studyRemind: '学习提醒',
      monthlyPlan: '月度计划',
    },
    uploadType: {
      all: '全部',
      video: '视频',
      audio: '音频',
      document: '文档',
      picture: '图片',
      compressedPackage: '压缩包',
      scorm: 'SCORM教材包',
      h5: 'H5教材包',
    },
    lecturerType: {
      internal: '内部讲师',
      external: '外部讲师',
    },
    gainCertOrNot: {
      gained: '已获得',
      notGain: '未获得',
    },
    TrainingStatusEnum: {
      notStart: '未开始',
      ongoing: '进行中',
      ended: '结束',
    },
    examStatusEnum: {
      waitTest: '待考试',
      pass: '及格',
      fail: '不及格',
      waitMark: '待阅卷',
      absent: '缺考',
    },
    monthKeyList: {
      jan: '1月',
      feb: '2月',
      mar: '3月',
      apr: '4月',
      may: '5月',
      jun: '6月',
      jul: '7月',
      aug: '8月',
      sep: '9月',
      oct: '10月',
      nov: '11月',
      dec: '12月',
    },
  },
  VX_certificate: {
    certificateSearch: '证书查询',
    search: '查询',
    certificateDetails: '证书详情',
    certificateNoPlaceHolder: '请输入证书号码',
    pleaseInputcertificateNo: '请输入证书编号！',
  },
  VX_development: {
    finishEvaluate: '完成评估',
    abilityLevel: '能力等级',
    levelDescription: '等级描述',
    highPerformanceBehaviorDesc: '高绩效行为描述',
    evaluateUser: '评估对象',
    abilities: '能力项',
    previousAbility: '上一个能力项',
    nextAbility: '下一个能力项',
    evaluateTip: '尚未完成评估，确定退出',
    evaluate: '评估',
    evaluationReport: '评估报告',
    evaluatedUser: '被评估人',
    reportTime: '报告时间',
    abilityName: '能力项名称',
    positionRequirement: '岗位需求',
    superiorEvaluation: '上级',
    subordinateEvaluation: '下级',
    sameLevelEvaluation: '平级',
    finalResult: '最终结果',
    completeEvaluationTask: '完成评估任务',
    completeProgramme: '完成提示方案',
    othersEvaluateTip: '他评评估进度变化规则: 相应被评估人关联的所有需要评估的人评估完成后，才会汇总完成评估部分的进度变化。<br> 举例：他评维度有两人，分别是A和B。A评估完了，界面上的他评的进度不会变化，需要等B评估完成才会汇总进度变化。',
    evaluatedUserSearchPlaceholder: '根据被评估人搜索',
    taskStatusPlaceholder: '请选择任务状态',
    batchEvaluate: '批量评估',
    evaluationRelation: '评估关系',
    evaluationStatusUpdateTime: '状态更新时间',
    taskStatus: '任务状态',
    evaluationStatus: '评估状态',
    evaluated: '已评估',
    notEvaluate: '待评估',
    waitingOthersEvaluate: '等待他评',
    startEvaluate: '开始评测',
    evaluationTestReport: '评测报告',
    advanceProgramme: '提升方案',
    commonAbility: '通用能力',
    positionAbility: '岗位能力',
    positionDemand: '岗位要求',
    previous: '上一个',
    next: '下一个',
    abilityExplanation: '能力项释义',
    historyEvaluationTip: '任务结果以最新的评估任务能力项结果为准',
    evaluationDimension: '评估维度',
    abilityCount: '能力项数',
    evaluationTime: '评估时间',
    historyEvaluation: '历史评估',
    criticalTask: '关键任务',
    totalCount: '总数',
    description: '描述',
    qualificationTipOne: '达成关键任务是完成岗位晋升的关键一步',
    requireCertificate: '所需证书',
    qualificationTipTwo: '获得所需证书是完成岗位晋升的关键一步',
    organizationContribution: '组织贡献',
    qualificationTipThree: '获得组织贡献是完成岗位晋升的关键一步',
    positionInfomation: '岗位信息',
    positionVideoIntroduction: '岗位视频介绍',
    postChannelRankName: '职级',
    positionResponsibility: '岗位职责',
    inPositionRequirement: '任职要求',
    studyProgramme: '学习方案',
    qualificationProgress: '合格进度',
    completeProgress: '进度完成{percent}%',
    taskItem: '任务项',
    seekAllCriticalTask: '查看全部关键任务',
    seekAllRequireCertificate: '查看全部所需证书',
    seekAllOrganizationContribution: '查看全部组织贡献',
    improvementSuggestion: '提升建议',
    ability: '能力项',
    importResult: '导入结果',
    taskResult: '任务结果',
    developmentTip: '当前存在兼岗情况，可在此切换岗位查看不同',
    developmentDescriptionTip: '说明：此处展示当前岗位已加入的可晋升通道，如可晋升岗位与当前岗位不属于同一通道下，则展示在跨通道晋升内',
    seek: '前往查看',
    developmentEmptyTip: '您当前没有岗位，请联系管理员加入岗位',
    positionNotPublished: '该岗位未发布',
    positionNoStudyProgramme: '该岗位未配置学习方案',
    evaluationAbility: '评估能力',
    relateTypeEnum: {
      subEvaluate: '下级评估',
      levelEvaluate: '平级评估',
      superEvaluate: '上级评估',
    },
    postType: {
      prePosition: '前序岗位',
      curPosition: '当前岗位',
      proPosition: '晋升岗位',
    },
  },
  VX_home: {
    taskNameShow: '此处显示任务名称',
    designatorNameShow: '此处显示用户昵称',
    courseNameShow: '此处显示课程名称',
    noLecturer: '暂无讲师',
    rankList: '排行榜',
    creditsRank: '学分排行',
    integralRank: '积分排行',
    certificateRank: '证书排行',
    rank: '排行',
    totalCredits: '总学分',
    totalCertificates: '总证书',
    examNameShow: '此处显示考试名称',
    userName: '昵称',
    department: '所属部门',
    examScore: '考试成绩',
    examRankList: '考试公示榜',
    noAuthTip: '暂无权限',
    noAuthTipDetail: '根据后台配置规则，您暂无权限查看当前考试排行榜',
    ranking: '排名',
    examScores: '考试得分',
    examViewTip: '请选择需要查询的考试，排行将按考试具体配置以准',
    score: '成绩',
    pageCountTip: '共{count}页',
    dataCountTip: '{count}条数据',
    studyNameShow: '此处显示任务名称',
    learnTaskNameShow: '此处显示学习任务名称',
    name: '员工昵称',
    investigateTip: '温馨提示：本问卷为匿名问卷，您可以放心的如实填答问卷，感谢配合！',
    gradeDescriptionPlaceholder: '请输入评分说明',
    mustInput: '（必填）',
    phoneNumPlaceholder: '请输入手机号',
    phoneNumInputTip: '请输入正确的手机号',
    enableSmsVerifyPlaceholder: '请输入验证码',
    commitSuccessTip: '提交成功！感谢您完成此次问卷调查',
    viewResult: '查看统计结果 >',
    viewOther: '查看其他',
    phoneNumberFalseTip: '第{key}道题目的手机号不正确，请重新输入',
    answerInputTip: '请选择第{index}道题目的答案，再提交问卷。',
    otherInputTip: '请输入第{index}道题目的其他选项的详细内容，再提交问卷。',
    inputTip: '请输入第{index}道题目的答案，再提交问卷。',
    minLengthInputTip: '第{index}道题目的答案最少{length}字',
    phoneNumberTip: '请输入第{index}道题目的手机号，再提交问卷。',
    enableSmsVerifyTip: '请输入第{index}道题目的短信验证码，再提交问卷。',
    gradeTip: '请选择第{index}道题目的评分，再提交问卷。',
    gradeDescriptionTip: '请填写第{index}道题目的评分说明，再提交问卷。',
    writeCount: '截止目前已有{count}名用户参与本次问卷调查，如下为其数据统计结果：',
    statisticsResultTip: '仅支持统计单选题、多选题、评分',
    questionOption: '题目选项',
    totalCount: '合计数',
    percent: '占比',
    gradeOption: '评分选项',
    optionCharacter: '选项文字',
    scores: '分值',
    writeCounts: '本题填写人数',
    noStatistics: '暂未统计内容',
    noLecturerName: '暂无讲师名称',
    lecturerField: '此处显示讲师擅长领域',
    integralMall: '积分商城',
    toIntegralMall: '前往积分商城兑换商品',
    toExchange: '去兑换',
  },
  VX_knowledge: {
    playErr: '播放出错提示',
    playErrTip: '播放异常 { count }秒才可以继续播放! 当次学习记录作废！',
    noNextChapterTip: '没有下一章',
    noNextSectionTip: '没有下一节',
    autoPlay: '自动播放下一章节！',
    videoNotUpdate: '该视频未更新',
    videoWatchTip: '购买后才可以观看?',
    enrollTip: '请登录后再报名?',
    lecturer: '授课讲师',
    attendenceArrangement: '考勤安排',
    userNamePlaceholder: '根据姓名搜索',
    deptPlaceholder: '请选择部门',
    pictureUserName: '头像/姓名',
    empNo: '工号',
    post: '所属岗位',
    email: '联系邮箱',
    joinTime: '加入时间',
    trainingProgress: '培训总进度',
    signInTime: '签到时间',
    signOutTime: '签退时间',
    traningDuration: '线下培训时长',
    assessScore: '考核成绩',
    assessComment: '考核评语',
    assessAttachmentCount: '考核评分附件',
    assessUserName: '录入成绩人员',
    assessAttachment: '评分附件',
    noResource: '暂无可学习的资源~',
    chapterCount: '第{count}章',
    relatedLecturer: '关联讲师',
    myProgress: '我的进度',
    progressFinished: '进度完成{percent}%',
    examPassCount: '考试及格{count}场',
    practicePassCount: '实操作业通过{count}场',
    applyNotPass: '报名不通过，查看原因',
    reApply: '重新报名',
    learned: '{count}人已学习',
    rejectReason: '驳回原因',
    courseLoadingFailTip: '课程信息加载失败',
    dataLoadingFailTip: '数据加载失败，请稍后重试',
    gradeGroupTitle: '星评（{count}）',
    comprehensiveGrade: '综合评分',
    gradeTime: '评分时间',
    auditScore: '审核得分',
    waitingGrade: '等待讲师评分',
    morning: '上午',
    afternoon: '下午',
    attendanceManager: '考勤负责人',
    notSignIn: '未签到',
    notSignOut: '未签退',
    courseExpired: '课程已过期',
    myGrade: '我的评分',
    daysAgo: '{day}天之前',
    hoursAgo: '{hour}小时之前',
    minutesAgo: '{minute}分钟之前',
    taskExpired: '任务已过期',
    evaluatedLecturer: '已评价讲师',
    evaluateLecturer: '评价讲师',
    evaluatedCourse: '已评价课程',
    evaluateCourse: '评价课程',
    from: '来自',
    taskContent: '作业内容',
    count: '{count}个',
    evaluationTip: '感谢您的评价!',
    signStatus: {
      normal: '正常',
      late: '迟到',
      leaveEarlier: '早退',
      absent: '缺勤',
      askingForLeave: '请假',
    },
    LiveStatusEnum: {
      notLive: '待直播',
      enterLive: '进入直播',
      ended: '已结束',
      replay: '回放观看',
    },
  },
  VX_utils: {
    yearsAgo: '{year}年前',
    monthsAgo: '{month}个月前',
    weeksAgo: '{week}周前',
    daysAgo: '{day}天前',
    hoursAgo: '{hour}小时前',
    minutesAgo: '{minute}分钟前',
    justNow: '刚刚',
    oneDayAgo: '1天前',
    month: '月',
    date: '日',
    hours: '时',
    minutes: '分',
    hour: '小时',
    day: '天',
    second: '秒',
    minute: '分钟',
  },
  VX_company: {
    companySelect: '选择企业',
    myCompany: '我的企业',
    companyCreateTip: '最多创建10个企业',
    registerCompany: '注册企业',
    enterCompany: '进入企业',
    basicInfoInput: '填写基本信息',
    companyCreationSuccessTip: '恭喜你！企业创建成功,获得7天免费试用',
    backToHome: '回到首页',
  },
  VX_header: {
    center: '机构中心',
    keywordInputPlaceholder: '请搜索关键词',
    targetNum: {
      1: '首页',
      2: '知识库',
      3: '讲师中心',
      4: '积分商城',
      5: '人才发展',
      6: '个人中心',
    },
    languageTypeEnum: {
      zh: '中文',
      en: 'English',
      ja: '日本語',
    },
  },
  VX_login: {
    agreement: '协议',
    refuse: '拒绝',
    agree: '同意',
    contactManagerTip: '请联系管理员',
    pleaseInputLoginPassword: '请设置8-30位数字、字母符号组合',
    sendCodeSuccess: '验证码发送成功',
    reGet: '重新获取',
    noAuth: '暂无权限查看',
  },
  VX_integral: {
    exchangeCondition: '兑换条件',
    conditionTip: '需学完以下全部内容方可兑换',
    toLearn: '前往学习',
    studyWarningTipOne: '此内容已被取消发布暂不可学习，请联系管理员',
    studyWarningTipTwo: '您无权限查看此内容，如需查看请联系管理员',
    consumeIntegral: '需消耗:<span><b>{count}</b>积分</span>',
    alreadyExchangeCount: '已兑{count}',
    deadline: '截止时间',
    finishSpecificContent: '学完指定内容',
    afterExchangingNotice: '兑后须知',
    exchangeTip: '兑换后不支持在线退换',
    surplusStock: '剩余库存',
    piece: '件',
    exchangeLimit: '（限兑{count}件）',
    immediateExchange: '立即兑换',
    noStock: '已兑光',
    confirmOrder: '确认订单',
    stockCount: '库存{count}件',
    consumption: '需消耗',
    exchangeSuccess: '兑换成功!',
    goodsDescription: '商品描述',
    rankType: '排序方式',
    integralRange: '积分区间',
    stockStatus: '库存状态',
    inStock: '有库存',
    integralConversionConditionTypeEnum: {
      course: '课程',
      studyProject: '学习项目',
      studyTask: '学习任务',
    },
  },
  VX_lecturer: {
    inputScore: '录入考核评分({score})',
    notInput: '待录入',
    hasInput: '已录入',
    getScore: '得分：',
    pass: '及格',
    assessComment: '评语：',
    uploadAttachment: '上传附件：',
    assessTip: '上传的附件会对学员展示。支持文档、压缩包，最多支持5个附件',
    inputScorePlaceholder: '请输入得分',
    uploadData: '上传资料',
    attachmentTip: '支持图片、文档、最多支持10个附件, 上传大小不能超过 2G',
    uploadAttachmentPlaceholder: '请选择附件上传',
    evaluateUserCount: '评价学员({count})',
    notEvaluate: '待评价',
    evaluated: '已评价',
    noLabel: '暂无标签',
    courseTaughtCount: '{count}个已授课程',
    questionnaireCount: '问卷({count})',
    courseCount: '课程数量',
    studyProjectCount: '学习项目数',
    lecturerIntroduction: '讲师介绍',
    offlineCourse: '线下课',
    notStartCount: '未开始({count})',
    liveCount: '进行中({count})',
    endedCount: '已结束({count})',
    address: '地址',
    purpose: '目的',
    assessScore: '考核评分',
    evaluateUser: '评价学员',
    searchByName: '根据{name}名称搜索',
    lecturerLevel: '讲师等级',
    searchByLecturerPlaceholder: '根据讲师名称搜索',
    courseTypeEnum: {
      onlineCourse: '线上课',
      pictureCourse: '图文课',
      liveCourse: '直播课',
      studyProject: '学习项目',
      offlineCourse: '线下课',
    }
  },
  VX_common: {
    callCustomerService: '联系客服',
    customerServiceQQ: '客服QQ',
    link: '友情链接',
    websiteNav: '网站导航',
  },
  VX_register: {
    commitApplication: '提交申请',
    pleaseInputUserName: '请输入用户姓名',
    pleaseInputPhone: '请输入手机号码',
    pleaseInputCompanyName: '请输入公司名称',
    pleaseSelectEntIndustry: '请选择企业行业',
    pleaseSelectEntScale: '请选择公司规模',
    pleaseInputCity: '请输入城市',
    pleaseInputProvince: '请输入省份',
  },
  VX_component: {
    faceRecognitionAuth: '人脸识别认证',
    clickSign: '点我打卡',
    remainSeconds: '剩余{second}秒',
    faceRecognitionTipOne: '1.请先核对您的真实姓名、身份证号信息是否正确可使用；',
    faceRecognitionTipTwo: '2.确认是否有摄像头或者是否已同意授权网站使用摄像头？若无法使用，请登录小程序或者APP进行人脸识别；',
    faceRecognitionTipThree: '3.人脸识别剩余打卡次数<span>{remainCount}</span>次，当前已失败<span>{currentErrorCount}</span>次，超出将不可继续进行考试或学习，请知晓！',
    verifyIdentity: '核对身份',
    validateSuccess: '验证成功',
    signSuccess: '打卡成功',
    modifyInfo: '修改实名信息',
    realName: '真实名称',
    realNameInputPlaceholder: '请输入真实名称',
    idCard: '身份证',
    idCardInputPlaceholder: '请输入身份证号',
    signTip: '正在录像！请不要转头或者把头移出摄像头里',
    startAuth: '开始认证',
    resetTip: '请确定身份证号码和姓名是否正确？',
    signConfirmTips: '<p style="text-align: left;margin-bottom: 30px">当前需要人脸认证，请先补全身份证号和姓名再来{typeContent}</p>',
    study: '学习',
    confirmTip: '人脸识别提示',
    confirmInput: '前往填写',
    recognitionNoTimes: '您的人脸识别校正次数已用完，不可再进入{typeContent}。如有需要请联系管理员',
    errorTimesLimit: '今日人脸识别错误次数已经超出限制了，不可再进入{typeContent}。如有需要请联系管理员',
    confirm: '我知道了',
    signVideoUploadTip: '正在上传打卡视频，请不要关闭！',
    close: '关闭（{second}）',
    reAuth: '重新认证',
    confirmIdCardAndName: '请确定身份证号码和姓名是否正确？',
    checkingCamera: '检测摄像头中...',
    validating: '正在验证中，请勿离开此页...',
    faceRecognitionOvertime: '人脸识别超时！',
    cameraAbnormal: '摄像头异常...',
    cameraAbnormalTip: '请确认一下是否有摄像头或者是否点击同意授权网站使用摄像头？若没有摄像头，请登录小程序或者APP进行人脸识别？',
    errorTip: '错误提示',
    relogin: '重新登录授权摄像头',
    uploadPictureFailure: '上传图片出错，请稍后重试',
    unfold: '展开',
    fold: '收起',
  },
  VX_error: {
    serviceError: '服务器错误',
    tipOne: '啊呀，不小心服务器出错了！',
    tipTwo: '此页面可能有bug，请联系管理员！',
    tipThree: '光速返回',
  }
}