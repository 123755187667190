import request from '~/api/request'

// 专区课程列出
export function getCourseList(type) {
  return request().get(`/system/auth/pc/zone/info/course/list?relationType=${type}`)
}

// Banner图列出
export function getBannerSettingList() {
  return request().get('/system/auth/banner/setting/list')
}

// 专区讲师列出
export function getLecturerList() {
  return request().get('/system/auth/pc/zone/info/lecturer/list')
}

// 待阅读消息
export function getAnnouncementCount() {
  return request().post('/user/auth/announcement/user/record/wait/read/count')
}

// 学习动态分页
export function getDynamicPage(param = {}, page = 1, size = 10) {
  return request().post('/course/auth/study/dynamic/page', { pageCurrent: page, pageSize: size, ...param })
}

// 用户学习任务基本信息分页
export function AuthStudyTaskPage(param = {}, page = 1, size = 10) {
  return request().post('/course/auth/study/task/page', { pageCurrent: page, pageSize: size, ...param })
}

// 公告 预览
export function AnnouncementUserRecordHomePreviewList(param = {}, page = 1, size = 5) {
  return request().post('/user/auth/announcement/user/record/page', { pageCurrent: page, pageSize: size, ...param })
}

// 课程信息分页 最新
export function courseInfoPageLast(params = {}, page = 1, size = 8) {
  return request().post('/course/auth/course/info/page', { pageCurrent: page, pageSize: size, ...params })
}

// 获取LOGO
export function getLogo(token) {
  return request(token).get('/system/auth/pc/zone/info/logo')
}

// 专区课程列出

// PC专区列出
export function authPcZoneInfoList() {
  return request().get('/system/auth/pc/zone/info/list')
}

// 汇总所有未读通知消息
export function getAllNoticeCount() {
  return request().post('/user/auth/user/msg/notice/collect/count')
}

// 用户考试任务分页
export function AuthExamTaskRecordPage(param = {}, page = 1, size = 10) {
  return request().post('/exam/auth/user/exam/task/record/page', { pageCurrent: page, pageSize: size, ...param })
}

// 用户考试任务分页
export function disclosureList() {
  return request().post('/exam/auth/user/exam/task/record/exam/disclosure/list ')
}

// 积分排名前3名
export function accountIntegralRankPage(param = {}, page = 1, size = 3) {
  return request().post('/user/auth/enterprise/user/account/integral/rank/page', { pageCurrent: page, pageSize: size, ...param })
}

// 当前用户积分排名
export function accountCurrentIntegralRank() {
  return request().get('/user/auth/enterprise/user/account/current/integral/rank')
}

// 学分排名前3名
export function accountCreditHourRankPage(param = {}, page = 1, size = 3) {
  return request().post('/user/auth/enterprise/user/account/credit/hour/rank/page', { pageCurrent: page, pageSize: size, ...param })
}

// 当前用户学分排名
export function accountCurrentCreditHourRank() {
  return request().get('/user/auth/enterprise/user/account/current/credit/hour/rank')
}

// 证书排名前3名
export function accountCertificateRankPage(param = {}, page = 1, size = 3) {
  return request().post('/user/auth/enterprise/user/account/certificate/rank/page', { pageCurrent: page, pageSize: size, ...param })
}

// 当前用户证书排名
export function accountCurrentCertificateRank() {
  return request().get('/user/auth/enterprise/user/account/current/certificate/rank')
}

/**
 * 获取文件下载链接
 * @param params
 */
export function exportRecordGetFileDownloadUrl(params) {
  return request().get('/data/crm/export/record/get/file/download/url', { params: params })
}

/**
 * 导出记录删除
 * @param params
 */
export function exportRecordDelete(params) {
  return request().delete('/data/crm/export/record/delete?id=' + params.id)
}

/**
 * 导出记录删除所有
 */
export function exportRecordAllDelete() {
  return request().delete('/data/crm/export/record/all/delete')
}

/**
 * 导出记录分页
 * @param param
 * @param page
 * @param size
 */
export function exportRecordPage(param = {}, page = 1, size = 10) {
  return request().post('/data/crm/export/record/page', { pageCurrent: page, pageSize: size, ...param })
}

// 考试公示榜
export function getExamRankList(data) {
  return request().post('/exam/auth/user/exam/task/record/rank', data)
}
// 当前用户在此次考试排名
export function getCurUserRank(examId) {
  return request().get('/exam/auth/user/exam/task/record/current/exam/rank?examId=' + examId)
}

// pc 装修

export function navView() {
  return request().get('/system/auth/pc/portal/nav/view')
}

export function portalList() {
  return request().get('/system/auth/pc/portal/list')
}

export function portalDetailView(id) {
  return request().get('/system/auth/pc/portal/detail/view?id=' + id)
}
