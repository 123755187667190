// import VueCookie from 'vue-cookie'
import Cookies from 'js-cookie'
export default {
  // 获取服务端cookie
  getInServer(req) {
    const serviceCookie = {}
    if (req && req.headers && req.headers.cookie) {
      req.headers.cookie.split(';').forEach(function(val) {
        const parts = val.split('=')
        serviceCookie[parts[0].trim()] = (parts[1] || '').trim()
      })
    }
    return serviceCookie
  },
  // 获取客户端cookie
  getInClient(key) {
    const tokenInfo = Cookies.get(key)
    // const tokenInfo = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyTm8iOiIyMDIxMDYwOTEwNTIwMTYxMDg1IiwiaXNzIjoiUk9OQ09PIiwidHlwZSI6InBjIiwiZXhwIjoxNjMzMjQwNDk3fQ.ctF11qNdz8EC3UfajYmhbNdEDHhNvFTrH_DAhaLFwog'
    return tokenInfo
  },
  // 获取客户端cookie
  setInClient({ key, val }) {
    // const hostname = window.location.hostname
    const option = {
      expires: 1
    }
    // if (hostname !== 'localhost') {
    //   const arr = hostname.split('.')
    //   option.domain = arr[arr.length - 2] + '.' + arr[arr.length - 1]
    // }
    // if (hostname === '127.0.0.1') {
    //   delete option.domain
    // }
    Cookies.set(key, val, option)
  },
  // 删除客户端cookie
  delInClient(key) {
    // const hostname = window.location.hostname
    const option = {
      expires: 1
    }
    // if (hostname !== 'localhost') {
    //   const arr = hostname.split('.')
    //   option.domain = arr[arr.length - 2] + '.' + arr[arr.length - 1]
    // }
    // if (hostname === '127.0.0.1') {
    //   delete option.domain
    // }
    Cookies.remove(key, option)
  }
}
